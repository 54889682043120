.element {
  animation-name: float;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes float {
  0%{
    transform: translateY(0);
  }

  50%{
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}